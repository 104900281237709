<template>
	<WwModal
		:modal-id="ADD_TO_CART_MODAL"
		modal-border-radius="rounded-t-2xl md:rounded-3xl"
		modal-margin="m-0"
		modal-padding="p-2 md:p-3"
		modal-position="items-end md:items-center"
		max-width="w-full md:max-w-lg"
		:transition-direction="!isMobile ? 'down' : 'bottom'"
	>
		<template #close="{ handleHideButtonClick }">
			<div class="absolute top-4 right-3 md:right-6">
				<button
					type="button"
					class="flex items-center justify-center w-8 h-8 text-black bg-white rounded-full hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-green-300"
					@click="handleHideButtonClick"
				>
					<span class="sr-only">
						Close panel
					</span>
					<CloseIcon />
				</button>
			</div>
		</template>
		<template #header="{ handleHideButtonClick }">
			<div
				:class="[
					isExpanded ? 'scale-x-100' : 'scale-x-0',
					'md:hidden absolute transition-all duration-500 transform left-1/2 -translate-x-1/2 -translate-y-1/2 top-1.5 h-1.5 rounded-full w-20 shadow-inner bg-green-500'
				]"
				@click="handleHideButtonClick"
			/>
			<div class="absolute w-4/5 text-2xl font-bold truncate transition-all ease-in-out transform left-5 md:left-8 top-4">
				Product added!
			</div>
		</template>
		<template #body="{ handleHideButtonClick }">
			<div class="grid content-center justify-center grid-cols-1 pt-8 transition-all ease-in-out min-h-28">
				<CartProductCard
					:product="product"
					:listing-id="String(businessId)"
					:is-modal="true"
					hide-delete
				/>
				<div class="flex items-end justify-between w-full px-4 py-2">
					<div
						v-if="hasDeliveryMinimum"
						class="w-1/2 text-xs"
					>
						Note: There is a minimum purchase amount [ {{ formattedDeliveryMinimum }} ] for delivery service.
					</div>
					<div class="text-base text-right text-gray-500 grow">
						Your Cart: <span class="text-lg font-bold text-black">
							${{ subtotal }}
						</span>
					</div>
				</div>
				<div class="grid grid-cols-2 gap-2 px-4 pb-2.5 xs:text-sm">
					<button
						class="px-2 font-bold text-center text-green-500 uppercase transition-all duration-100 border-2 border-green-500 rounded-md h-11 md:h-13 ease xl:hover:shadow"
						@click="handleHideButtonClick"
					>
						Keep Shopping
					</button>
					<button
						class="px-2 font-bold text-center text-white uppercase transition-all duration-100 bg-green-500 border-2 border-green-500 rounded-md xl:hover:shadow ease h-11 md:h-13"
						@click="handleCheckoutButtonClick"
					>
						Checkout
					</button>
				</div>
			</div>
		</template>
		<template #footer>
			<div class="flex w-full py-1">
				<button
					class="px-4 py-1 mx-auto font-bold text-center text-green-500 transition-all duration-100 rounded-md xl:hover:bg-gray-200 hover:text-purple-500 ease"
					@click="handleViewCartButtonClick"
				>
					View Cart
				</button>
			</div>
		</template>
	</WwModal>
</template>

<script async>
import { mapGetters, mapMutations } from 'vuex'

import CartProductCard from '@/components/cart/CartProductCard.vue'
import CloseIcon from '@/components/icons/CloseIcon.vue'
import WwModal from '@/components/UI/WwModal.vue'
import { ADD_TO_CART_MODAL } from '@/constants/modal/names.js'
import { CART_SLIDE_OVER } from '@/constants/slideOver/names.js'
import getCartRouteName from '@/utils/cart/get-route-name.js'

export default {
	components: {
		WwModal,
		CartProductCard,
		CloseIcon
	},
	props: {
		product: {
			type: Object,
			required: true
		},
		subtotal: {
			type: String,
			required: true
		},
		carts: {
			type: Array,
			required: true
		}
	},
	data() {
		return {
			ADD_TO_CART_MODAL,
			isExpanded: false
		}
	},
	computed: {
		...mapGetters([ 'isMobile' ]),
		...mapGetters('modal', [ 'activeModalId' ]),
		...mapGetters('business', [ 'businessId', 'deliveryMinimum' ]),
		cartRouteName() {
			return getCartRouteName(this.$route)
		},
		hasDeliveryMinimum() {
			return !!this.deliveryMinimum
		},
		formattedDeliveryMinimum() {
			return this.deliveryMinimum?.toFixed(2) || 0
		},
		currentListingCart() {
			return this.carts.find(cart => cart.listing?.id == this.businessId)
		}
	},
	watch: {
		activeModalId(newValue) {
			if (newValue === ADD_TO_CART_MODAL) {
				setTimeout(() => {
					this.isExpanded = true
				}, 150)
			} else {
				this.isExpanded = false
			}
		}
	},
	methods: {
		...mapMutations('slideOver', [ 'showSlideOver' ]),
		...mapMutations('modal', [ 'closeModal' ]),
		...mapMutations('cart', [ 'updateTemporaryCartData' ]),
		handleViewCartButtonClick() {
			this.isExpanded = false
			this.updateTemporaryCartData({
				items: this.currentListingCart.items?.map(item => {
					return {
						...item,
						product_id: item.productId
					}
				}) || [],
				num_items: this.currentListingCart.numItems,
				subtotal: this.currentListingCart.subTotal,
				name: this.currentListingCart.listing?.name,
				logo: this.currentListingCart.listing?.logo?.fileName,
				url: this.currentListingCart.listing?.url,
				listingId: this.currentListingCart.listing?.id,
				settings: this.currentListingCart.listing?.settings
			})
			this.closeModal(ADD_TO_CART_MODAL)
			this.showSlideOver(CART_SLIDE_OVER)
		},
		handleCheckoutButtonClick() {
			this.isExpanded = false
			this.closeModal(ADD_TO_CART_MODAL)
			this.$nextTick(() => {
				setTimeout(() => {
					this.$router.push({ name: this.cartRouteName })
				}, 200)
			})
		}
	}
}
</script>
